/* eslint-disable react/no-unknown-property */
import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/imoveis.scss'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'

export default function Imoveis (props) {
  const breadC = {
    visible: true,
    color: 'black', // Options black || white
    items: [
      { path: '/', name: 'Home' },
      { path: '/imoveis/', name: 'Imoveis' }
    ]
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Imóveis - Dia Supermercados</title>
        <meta name="description" content="Você tem um imóvel e deseja alugá-lo ou vendê-lo? Entre em contato conosco por meio do nosso formulário para avaliarmos sua proposta!" />
        <meta name="keywords" content="imoveis, alugar imovel para supermercado, vender imovel para supermercado" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <section>
        <div className="position-relative w-100 control">
          <GatsbyImage
            image={props.data.bannerImoveis.childImageSharp.gatsbyImageData}
            alt='Background Imoveis'
            className="position-absolute h-100 w-100" />
          {/* <Img fluid={props.data.bannerImoveisMobile.childImageSharp.fluid} alt='Background Imoveis Mobile' className="position-absolute d-lg-none d-block w-100" /> */}
          <div className="container">
            <div className="row h-100">
              <div className="col-lg-5 col-10 superior bg-white">
                <h1 tabIndex={0} className="text-red mb-4">Oportunidade em Imóveis</h1>
                <p className="mb-4 mt-4 font-size-1">Mantendo seu agressivo ritmo de expansão, a rede de supermercados DIA está em busca de imóveis para a abertura de novas lojas.</p>
                <p className="mb-4 mt-4 font-size-1"><b>Você tem um imóvel e deseja alugá-lo ou vendê-lo?</b> Entre em contato conosco por meio do nosso formulário para avaliarmos sua proposta!</p>
                <a href="https://portaldiabrasil.service-now.com/captacao_externa.do" target="_blank" rel="noreferrer"><button type="button" className="btn btn-red-1 pt-3 pb-3 pr-4 pl-4 mt-3 font-size-13">Clique aqui para preencher o formulário</button></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`{
  bannerImoveis: file(relativePath: {eq: "imoveis/header-imoveis.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerImoveisMobile: file(
    relativePath: {eq: "imoveis/header-imoveis-mobile.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
